<template>
  <div class="d-flex flex-row">
    <column-picker
      :clearable="true"
      :label="$t('t.Conditions')"
      :selected-col-id.sync="selectedColId"
      :selected-cur-id.sync="selectedCurId"
      :attach="false"
      :filters="{ 'document-types': [['accounts'],[]] }"
    />
  </div>
</template>
<script>
export default {
  components: {
    ColumnPicker: () => import('@/components/column-picker')
  },
  computed: {
  },
  data () {
    return {
      selectedColId: null,
      selectedCurId: null

    }
  }

}
</script>
